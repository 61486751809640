import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

const setStickyitems = () => {
	jQuery(document).ready(function ($) {
		gsap.utils
			.toArray('.sticky_bottom_container')
			.forEach((cont, index) => {
				let sticky = cont.querySelector('.sticky_bottom');
				gsap.to(sticky, {
					scrollTrigger: {
						trigger: sticky,
						start: 'bottom bottom',
						end: `+=${cont.offsetHeight - sticky.offsetHeight}px`,
						//markers: true,
						pin: true,
						scrub: false,
					},
				});
			});

		gsap.utils
			.toArray('.sticky_text_center_container')
			.forEach((cont, index) => {
				let sticky = cont.querySelector('.sticky_text_center');
				gsap.to(sticky, {
					scrollTrigger: {
						trigger: sticky,
						start: 'center center',
						end: `+=${cont.offsetHeight - sticky.offsetHeight}px`,
						//markers: true,
						pin: true,
						pinSpacing: false,
						scrub: false,
					},
				});
			});
	});
};

export { setStickyitems };
